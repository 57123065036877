<template>
  <DrDrawer
    :shown="isVisible"
    :title="$t('room_updates.new_update')"
    :submit="$t('room_updates.compare_rooms')"
    :disallow-submit="isFormSubmitting"
    @submit="handleFormSubmit"
    @close="close"
  >
    <template #description>
      {{ $t("room_updates.new_description") }}
      <br />
      {{ $t("room_updates.new_note") }}
    </template>

    <ElAlert
      v-if="batchFormErrors.non_field_errors"
      class="auth-form__alert"
      :title="batchFormErrors.non_field_errors"
      type="error"
      show-icon
      :closable="false"
    />

    <DrDynamicFormClassic
      ref="batchFormRef"
      :schema="batchSchema"
      :entity="batch"
      :errors="batchFormErrors"
      @update="handleFormUpdate"
      @submit="handleFormSubmit"
    />
  </DrDrawer>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";

import { DrStore } from "@app/vue";
import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import { FieldSchemaType } from "@app/vue/components/client-dashboard/dynamic-form/types";
import { useFormHelper } from "@app/vue/shared/ui/dr-form";
import { type CreateBatchPayload, MultiRoomUpdateService } from "./api";

import type { Room } from "@app/vue/store/modules/client-dashboard/deals/types";

interface Emits {
  (event: "created", callback: () => void): void;
}

const emit = defineEmits<Emits>();

const { t } = useI18n();

const api = new MultiRoomUpdateService();

const isVisible = ref(false);

const batchFormRef = useTemplateRef("batchFormRef");

const batch = ref<CreateBatchPayload>({
  src_room_id: "",
  trg_room_ids: [],
  options: {},
});

const availableRooms = computed<Room[]>(() => {
  return DrStore.getters["clientDashboard/deals/getRooms"].filter(
    (r: Room) => r.access.administrator && !r.is_archived,
  );
});

// Disallow selecting src_room_id as a target room to avoid comparing it with itself
const targetRooms = computed(() => {
  const sourceRoomId = batch.value.src_room_id;
  if (sourceRoomId) {
    return availableRooms.value.filter((r) => r.uid !== sourceRoomId);
  }

  return availableRooms.value;
});

const batchSchema = computed(() => {
  const rooms: Room[] = DrStore.getters[
    "clientDashboard/deals/getRooms"
  ].filter((r: Room) => r.access.administrator && !r.is_archived);

  return [
    {
      type: FieldSchemaType.Select,
      prop: "src_room_id",
      label: t("room_updates.source_room"),
      placeholder: t("room_updates.select_a_source_room"),
      extra: {
        select_options: rooms.map((r) => ({
          label: r.title,
          value: r.uid,
        })),
      },
    },
    {
      type: FieldSchemaType.Select,
      prop: "trg_room_ids",
      label: t("room_updates.target_rooms"),
      isReadOnly: !batch.value.src_room_id,
      placeholder: !batch.value.src_room_id
        ? t("room_updates.select_a_source_room_first")
        : t("room_updates.select_target_rooms"),
      extra: {
        multiple: true,
        // It should be true, but in the current state,
        // it removes all pre-selected items when you try to filter
        filterable: false,
        select_options: targetRooms.value.map((r) => ({
          label: r.title,
          value: r.uid,
        })),
      },
    },
  ];
});

const handleFormUpdate = (payload: { field: string; value: any }) => {
  const field = payload.field as keyof CreateBatchPayload;
  batch.value[field] = payload.value;
};

const {
  formErrors: batchFormErrors,
  hookFormSubmitPromise,
  isFormSubmitting,
  resetErrors,
  resetError,
} = useFormHelper<CreateBatchPayload>();

const handleFormSubmit = () => {
  resetErrors();

  batchFormRef.value?.validate().then((isValid) => {
    if (!isValid) return;

    hookFormSubmitPromise(api.createBatch(batch.value)).then(
      () => {
        $notifySuccess(t("room_updates.update_created"));

        emit("created", () => {
          isVisible.value = false;
        });
      },
      (r) => {
        $notifyDanger(t("room_updates.failed_to_create"));
      },
    );
  });
};

interface InitialState {
  sourceRoomId: Room["uid"];
  targetRoomsIds: Room["uid"][];
}

const open = (initialState?: InitialState) => {
  if (initialState) {
    batch.value.src_room_id = initialState.sourceRoomId;
    batch.value.trg_room_ids = initialState.targetRoomsIds;
  }

  isVisible.value = true;
};

const close = () => {
  isVisible.value = false;

  batch.value = {
    src_room_id: "",
    trg_room_ids: [],
    options: {},
  };
};

defineExpose({
  open,
  close,
});
</script>

<style module lang="scss"></style>

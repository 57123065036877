import { ROOM_DATA } from "@app/setups";
import { folderUrl } from "@app/setups/room-urls";
import { type Document } from "@app/vue/store/modules/room/documents/DocumentsApiService";

import type { UploadItem } from "@app/common/file-upload-helpers";
import type { Group } from "@app/vue/components/room/groups/types";
import type { FilePermissionsShort } from "@app/vue/store/pinia/room/filePermissions/FilePermissionsApi";
import type { AxiosResponse } from "axios";
import type { ComputedRef, InjectionKey } from "vue";

export const UploadListBatchInjectionKey: InjectionKey<{
  getDuplicatePrimaryButtonText: (
    batch: Batch,
    duplicate: Document | undefined,
  ) => string;
  onBatchAction: ComputedRef<(batch: Batch) => void>;
  onBatchRenamed: ComputedRef<(batch: Batch) => void>;
}> = Symbol("UploadListBatchInjectionKey");

export interface RowPermissions {
  can_view: boolean;
  can_download_watermaked: boolean;
  can_download_original: boolean;
  can_edit: boolean;
}

export type RowPermissionsKey = keyof RowPermissions;

export interface PermissionTableRow {
  group_id: number;
  group_name: string;
  has_access_to_folder: boolean;
  are_permissions_editable: boolean;
  permissions: RowPermissions;
  notify: boolean;
}

export enum FileStates {
  Pending = "pending",
  Uploading = "uploading",
  Cancelled = "cancelled",
  Resolved = "resolved",
  Rejected = "rejected",
}

export type UploadFileData = {
  file: DrUploadFile;
  index: number | undefined;
  name: string | undefined;
  mimetype: string | undefined;
  group_ids_to_notify?: string;
  notify?: number[];
  permissions?: string;
  _chunkFilename?: string;
  _chunkUploadID?: string;
};

export type UploadConfig = {
  url: string;
  data: UploadFileData;
  resumeChunkSize?: number;
  resumeSize?: Promise<number>;
  _start?: number;
};

export type UploadReponse = AxiosResponse<{
  id: number;
  index: string;
  name: string;
  uid: string;
  upload_information: Partial<DrUploadFileInfo>;
}>;

// This type is incomplete, it's missing a lot of keys that are being added
// dynamically in the code after uploadToFolder() is called.
export interface DrUploadFile extends File {
  path: string;
  relativePath?: string;
  url: string;
  displayName: string;
  displayIndex: string;
  drState: FileStates;
  drProgressLoaded: number;
  drProgressLoadedPercent: number;
  drIcon: string;
  uploadInfo: Partial<DrUploadFileInfo>;
  errorInfo: string[];
  resolve?: (value: UploadReponse | undefined) => void;
}
export interface DrUploadFileInfo {
  created: boolean;
  folder_id: number;
  id: number | null;
  index: number;
  name: string;
  suggested_mimetype: string;
  uid: string | null;
  uploadID: string;
  notify: number[];
  permissions: Record<Group["id"], FilePermissionsShort>;
  error: string[];
  uploadConfig?: UploadConfig;
}

export interface DrUploadDirectory {
  type: "directory";
  name: string;
  path: string;
  relativePath?: string;
  url?: string;
  displayName?: string;
  displayIndex?: string;
  drState?: FileStates;
  drIcon?: string;
  uploadInfo?: [];
  errorInfo?: unknown;
}

export interface DrUploadDirectoryInfo {
  created: boolean;
  id: number;
  index: string;
  name: string;
  path: string;
  uid: string;
  upload_information: [];
  error?: unknown;
}

export const isDrUploadDirectory = (
  value: unknown,
): value is DrUploadDirectory => {
  if (!value) return false;

  return (
    typeof value === "object" &&
    "type" in value &&
    "path" in value &&
    value.type === "directory" &&
    typeof value.path === "string"
  );
};

export const getDrUploadDirectory = (
  value: DrUploadDirectoryInfo,
): DrUploadDirectory => {
  const result: DrUploadDirectory = {
    drIcon: "icon_folder",
    type: "directory",
    name: value.name,
    path: value.path,
    uploadInfo: value.upload_information,
    drState: value.error ? FileStates.Rejected : FileStates.Resolved,
    displayName: value.error ? value.path : value.name,
    errorInfo: value.error,
    url: value.error ? undefined : folderUrl(ROOM_DATA.url, value.id),
    displayIndex: value.error ? undefined : value.index,
  };

  return result;
};

export const isDrUploadFile = (value: unknown): value is DrUploadFile => {
  if (!value) return false;

  return (
    typeof value === "object" && "type" in value && value.type !== "directory"
  );
};

export interface UploadItemFile extends UploadItem {
  size: number;
  file: DrUploadFile;
}

export type FileBatchItem = File | DrUploadFile;
export type FolderBatchItem = File | DrUploadFile | DrUploadDirectory;
export type BatchItem = FileBatchItem | FolderBatchItem;

export interface FileBatch {
  id: number;
  name: string;
  originalName: string;
  iconClass: string;
  type: "file";
  items: FileBatchItem[];
}

export interface FolderBatch {
  id: number;
  name: string;
  originalName: string;
  iconClass: string;
  type: "folder";
  items: FolderBatchItem[];
}

export type Batch = FileBatch | FolderBatch;

<template>
  <div v-if="batch.batchState === BATCH_STATE.COMPARING" :class="$style.status">
    {{ statusText }}
    <DrNewLoader :class="$style.loader" />
  </div>

  <div
    v-if="batch.batchState === BATCH_STATE.READY_FOR_REVIEW"
    :class="$style.status"
  >
    <ElButton :class="$style.button" @click="openReviewUpdatesPanel">
      {{ statusText }}
    </ElButton>
  </div>

  <div v-if="batch.batchState === BATCH_STATE.APPLYING" :class="$style.status">
    {{ statusText }}
    <DrNewLoader :class="$style.loader" />
  </div>

  <div v-if="batch.batchState === BATCH_STATE.COMPLETED" :class="$style.status">
    <DrIcon size="sm" name="check" :class="[$style.icon, $style.success]" />
    {{ statusText }}
    <ElButton :class="$style.button" @click="openReviewAppliedUpdatesPanel">
      {{ $t("room_updates.see_updates") }}
    </ElButton>
    <DrTooltip :content="$t('room_updates.new_update_with_selected_rooms')">
      <ElButton :class="$style.squareButton">
        <DrIcon name="clone" @click="cloneUpdate" />
      </ElButton>
    </DrTooltip>
  </div>

  <div v-if="batch.batchState === BATCH_STATE.FAILED" :class="$style.status">
    <DrIcon
      size="sm"
      name="exclamation-triangle"
      :class="[$style.icon, $style.danger]"
    />
    {{ statusText }}
    <DrTooltip :content="$t('room_updates.new_update_with_selected_rooms')">
      <ElButton :class="$style.squareButton">
        <DrIcon name="clone" @click="cloneUpdate" />
      </ElButton>
    </DrTooltip>
  </div>

  <div v-if="batch.batchState === BATCH_STATE.EXPIRED" :class="$style.status">
    <DrTooltip :content="$t('room_updates.update_expired_tooltip')">
      <div>
        <DrIcon size="sm" name="clock" :class="[$style.icon, $style.neutral]" />
        {{ statusText }}
        <DrTooltip :content="$t('room_updates.new_update_with_selected_rooms')">
          <ElButton :class="$style.squareButton">
            <DrIcon name="clone" @click="cloneUpdate" />
          </ElButton>
        </DrTooltip>
      </div>
    </DrTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import DrIcon from "@shared/ui/dr-icon";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import { DrNewLoader } from "@app/vue/shared/ui/dr-loader";
import { BATCH_STATE, type BatchViewModel } from "./api";
import {
  createMultiRoomUpdateRefKey,
  reviewAppliedUpdatesRefKey,
  reviewUpdatesRefKey,
} from "./types";

interface Props {
  batch: BatchViewModel;
}

const props = defineProps<Props>();

const { t } = useI18n();

const createMultiRoomUpdatePanelRef = inject(createMultiRoomUpdateRefKey);
const reviewUpdatesPanelRef = inject(reviewUpdatesRefKey);
const reviewAppliedUpdatesPanelRef = inject(reviewAppliedUpdatesRefKey);

const BATCH_STATE_TEXT = computed(() => {
  return {
    [BATCH_STATE.COMPARING]: t("room_updates.batch_state.comparing"),
    [BATCH_STATE.READY_FOR_REVIEW]: t(
      "room_updates.batch_state.ready_to_be_applied",
    ),
    [BATCH_STATE.APPLYING]: t("room_updates.batch_state.applying"),
    [BATCH_STATE.COMPLETED]: t("room_updates.batch_state.completed"),
    [BATCH_STATE.FAILED]: t("room_updates.batch_state.failed_to_compare"),
    [BATCH_STATE.EXPIRED]: t("room_updates.batch_state.expired"),
  } as const;
});

const statusText = computed(
  () => BATCH_STATE_TEXT.value[props.batch.batchState] ?? t("shared.unknown"),
);

const openReviewUpdatesPanel = () => {
  reviewUpdatesPanelRef?.value?.open(props.batch.batchId);
};

const openReviewAppliedUpdatesPanel = () => {
  reviewAppliedUpdatesPanelRef?.value?.open(props.batch.batchId);
};

const cloneUpdate = () => {
  createMultiRoomUpdatePanelRef?.value?.open({
    sourceRoomId: props.batch.sourceRoomId,
    targetRoomsIds: props.batch.targetRooms.map((r) => r.id),
  });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography.scss";

.status {
  display: flex;
  align-items: center;

  :global {
    .el-button + .el-button {
      margin-left: 8px;
    }
  }
}

.icon {
  margin-right: 6px;
}

.loader {
  margin-left: 6px;
}

.button {
  margin-left: 12px;
}

.squareButton {
  padding: 4px;
  width: 32px;
  height: 32px;
}

.success {
  color: colors.$ad-3;
}

.danger {
  color: colors.$ad-2;
}

.warning {
  color: colors.$ad-10_5;
}

.neutral {
  color: colors.$pr-400;
}
</style>

<template>
  <DrDrawer
    :shown="isVisible"
    :title="panelTitle"
    size="xl"
    no-footer
    @close="close"
  >
    <DrNewLoader v-show="isLoading" />
    <ElAlert
      v-if="isError"
      :title="$t('room_updates.an_error_occured_while_loading')"
      type="error"
      show-icon
      :closable="false"
    />
    <div :class="$style.logs">
      <div
        :class="$style.log"
        v-for="[state, logs] in logsGrouppedByState"
        :key="state"
      >
        <div :class="$style.state">{{ STATE_LABEL[state] }}</div>

        <div v-for="(log, i) in logs" :key="i">
          <span
            v-if="log.level !== 'info'"
            :class="{
              [$style.level]: true,
              [$style.error]: log.level === 'error',
              [$style.warning]: log.level === 'warning',
            }"
          >
            {{ log.level }}:
          </span>
          {{ log.message }}
        </div>
      </div>
    </div>
  </DrDrawer>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrNewLoader } from "@shared/ui/dr-loader";

import { DrStore } from "@app/vue";
import { MultiRoomUpdateService, WORKFLOW_STATE } from "./api";

import type {
  RoomUpdateBatch,
  RoomUpdateBatchLogItem,
  RoomUpdateWorkflow,
} from "./api";
import type { Room } from "@app/vue/store/modules/client-dashboard/deals/types";

const getRoomByUid: (roomUid: string) => Room | undefined =
  DrStore.getters["clientDashboard/deals/getRoomByUid"];

const { t } = useI18n();

const api = new MultiRoomUpdateService();

const batch = ref<RoomUpdateBatch | null>(null);
const batchLogs = ref<RoomUpdateBatchLogItem[] | null>(null);

const updatesRoomId = ref<Room["uid"] | null>(null);

const panelTitle = computed(() => {
  const room: Room | undefined = updatesRoomId.value
    ? getRoomByUid(updatesRoomId.value)
    : undefined;

  return room
    ? `${room.title}: ${t("room_updates.update_log")}`
    : t("room_updates.update_log");
});

const STATE_LABEL: Record<RoomUpdateWorkflow["state"], string> = {
  [WORKFLOW_STATE.COMPARING]: "Comparison log",
  [WORKFLOW_STATE.APPLYING]: "Applying log",
} as Record<RoomUpdateWorkflow["state"], string>;

type LogsStateGroup = [
  RoomUpdateBatchLogItem["state"],
  RoomUpdateBatchLogItem[],
];
const logsGrouppedByState = computed((): LogsStateGroup[] => {
  if (!batch.value || !batchLogs.value) return [];

  const wfIds = new Set(
    batch.value.workflows.reduce<RoomUpdateWorkflow["id"][]>((acc, wf) => {
      if (wf.trg_room_id === updatesRoomId.value) acc.push(wf.id);
      return acc;
    }, []),
  );

  const stateToLogsMap = batchLogs.value.reduce((acc, log) => {
    if (!wfIds.has(log.workflow_id)) return acc;

    const list = acc.get(log.state) ?? [];
    list.push(log);
    acc.set(log.state, list);

    return acc;
  }, new Map<RoomUpdateBatchLogItem["state"], RoomUpdateBatchLogItem[]>());

  return Array.from(stateToLogsMap);
});

const isVisible = ref(false);

const isLoading = ref(false);
const isError = ref(false);

const open = (pBatchId: RoomUpdateBatch["id"], pRoomId: Room["uid"] | null) => {
  isVisible.value = true;

  updatesRoomId.value = pRoomId;

  isLoading.value = true;
  Promise.all([
    api.getBatch(pBatchId).then((b) => {
      batch.value = b;
    }),
    api.getBatchLogs(pBatchId).then((l) => {
      batchLogs.value = l;
    }),
  ])
    .then(
      () => {
        isError.value = false;
      },
      () => {
        isError.value = true;
      },
    )
    .finally(() => {
      isLoading.value = false;
    });
};

const close = () => {
  isVisible.value = false;

  batch.value = null;
  batchLogs.value = null;
  updatesRoomId.value = null;

  isLoading.value = false;
  isError.value = false;
};

defineExpose({
  open,
  close,
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.state {
  background-color: colors.$pr-600;
  color: colors.$pr-0;
  border-radius: 4px;
  padding: 2px 6px;
  margin-bottom: 10px;
}

.logs {
  padding-bottom: 15px;
}

.log {
  margin-bottom: 20px;
}

.level {
  text-transform: capitalize;
}

.warning {
  color: colors.$ad-10_5;
}

.error {
  color: colors.$ad-3;
}
</style>

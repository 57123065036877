import { ApiService } from "@app/vue/api-service";

import type { Room } from "@app/vue/store/modules/client-dashboard/deals/types";

export interface RoomUpdateBatchOptions {
  [key: string]: any;
}

export interface RoomUpdateBatch {
  id: string;
  state: BatchState;
  created_by_id: string;
  created_at: Date;
  workflows: RoomUpdateWorkflow[];
  src_room_id: string;
  options: RoomUpdateBatchOptions;
}

export interface RoomUpdateBatchLogItem {
  workflow_id: RoomUpdateWorkflow["id"];
  state: WorkflowState;
  message: string;
  level: "info" | "warning" | "error"; // UserLogLevel
  timestamp: Date;
}

export const BATCH_STATE = {
  COMPARING: "COMPARING",
  READY_FOR_REVIEW: "READY_FOR_REVIEW",
  APPLYING: "APPLYING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  EXPIRED: "EXPIRED",
};

export type BatchState = (typeof BATCH_STATE)[keyof typeof BATCH_STATE];

export const WORKFLOW_STATE = {
  COMPARING: "COMPARING",
  READY_FOR_REVIEW: "READY_FOR_REVIEW",
  APPLYING: "APPLYING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  EXPIRED: "EXPIRED",
} as const;

export type WorkflowState =
  (typeof WORKFLOW_STATE)[keyof typeof WORKFLOW_STATE];

export interface BaseRoomUpdateWorkflow {
  id: string;

  trg_room_id: string;

  state: WorkflowState;

  created_by_id: string;
  created_at: Date;

  initial_changes: null | ChangeOperation[];
  reviewed_changes: null | ChangeOperation[];
  unapplied_changes: null | ChangeOperation[];

  reviewed_by_id: null | number;
  reviewed_at: null | Date;

  finalized_at: null | Date;
}

export interface ComparingRoomUpdateWorkflow extends BaseRoomUpdateWorkflow {
  state: "COMPARING";

  initial_changes: null;
  reviewed_changes: null;
  unapplied_changes: null;

  reviewed_by_id: null;
  reviewed_at: null;

  finalized_at: null;
}

export interface ReadyForRewviewRoomUpdateWorkflow
  extends BaseRoomUpdateWorkflow {
  state: "READY_FOR_REVIEW";

  initial_changes: ChangeOperation[];
  reviewed_changes: null;
  unapplied_changes: null;

  reviewed_by_id: null;
  reviewed_at: null;

  finalized_at: null;
}

export interface ApplyingRoomUpdateWorkflow extends BaseRoomUpdateWorkflow {
  state: "APPLYING";
  initial_changes: ChangeOperation[];
  reviewed_changes: ChangeOperation[];
  unapplied_changes: ChangeOperation[];

  finalized_at: null;
}

export interface CompletedRoomUpdateWorkflow extends BaseRoomUpdateWorkflow {
  state: "COMPLETED";

  initial_changes: ChangeOperation[];
  reviewed_changes: ChangeOperation[];
  unapplied_changes: ChangeOperation[];

  finalized_at: Date;
}

export interface FailedRoomUpdateWorkflow extends BaseRoomUpdateWorkflow {
  state: "FAILED";

  initial_changes: null | ChangeOperation[];
  reviewed_changes: null | ChangeOperation[];
  unapplied_changes: null | ChangeOperation[];

  finalized_at: Date;
}

export interface ExpiredRoomUpdateWorkflow extends BaseRoomUpdateWorkflow {
  state: "EXPIRED";
  initial_changes: ChangeOperation[];
  reviewed_changes: null;
  unapplied_changes: null;

  finalized_at: null;
}

export type RoomUpdateWorkflow =
  | ComparingRoomUpdateWorkflow
  | ReadyForRewviewRoomUpdateWorkflow
  | ApplyingRoomUpdateWorkflow
  | CompletedRoomUpdateWorkflow
  | FailedRoomUpdateWorkflow
  | ExpiredRoomUpdateWorkflow;

export interface WorkflowToApply {
  workflow_id: RoomUpdateWorkflow["id"];
  reviewed_changes: ChangeOperation[];
}

export function isReadyForReview(
  wf: RoomUpdateWorkflow,
): wf is ReadyForRewviewRoomUpdateWorkflow {
  return wf.state === "READY_FOR_REVIEW";
}

export function isCompleted(
  wf: RoomUpdateWorkflow,
): wf is CompletedRoomUpdateWorkflow {
  return wf.state === "COMPLETED";
}

const CHANGE_OPERATION_TYPE = {
  CREATE_TASK: "create_task",
  CREATE_GROUP: "create_group",
  ADD_ROOM_MEMBER: "add_room_member",
} as const;

export type ChangeOperationType =
  (typeof CHANGE_OPERATION_TYPE)[keyof typeof CHANGE_OPERATION_TYPE];

export interface CreateTaskChangeOperation {
  op: "create_task";
  task: ChangeObject<"task">;
}

export interface CreateGroupChangeOperation {
  op: "create_group";
  group: ChangeObject<"grouppermission">;
}

export interface AddRoomMemberChangeOperation {
  op: "add_room_member";
  group: ChangeObject<"grouppermission">;
  member: ChangeObject<"grouppermissionmember">;
}

export type ChangeOperation =
  | CreateTaskChangeOperation
  | CreateGroupChangeOperation
  | AddRoomMemberChangeOperation;

export const CHANGE_OBJECT_TYPE = {
  GROUP_PERMISSION: "grouppermission",
  GROUP_PERMISSION_MEMBER: "grouppermissionmember",
  FOLDER: "folder",
  DOCUMENT: "document",
  CATEGORY: "category",
  TASK: "task",
} as const;

export type ChangeObjType =
  (typeof CHANGE_OBJECT_TYPE)[keyof typeof CHANGE_OBJECT_TYPE];

export interface ChangeObject<Type extends ChangeObjType = ChangeObjType> {
  type: Type;
  key: string;
  label: string;
}

export interface BatchViewModel {
  batchId: string;
  batchState: BatchState;
  workflows: RoomUpdateWorkflow[];
  date: Date;
  createdBy: {
    name: string;
    email: string;
    avatarUrl: string | undefined;
  };
  sourceRoomId: Room["uid"];
  sourceRoomTitle: Room["title"];
  targetRooms: { title: Room["title"]; id: Room["uid"] }[];
}

export interface CreateBatchPayload {
  src_room_id: string;
  trg_room_ids: string[];
  options: RoomUpdateBatchOptions;
}

export class MultiRoomUpdateService extends ApiService {
  private getUrl(endpoint: string) {
    return this.Urls[`api:client-dashboard:${endpoint}`];
  }

  public getBatches() {
    const url = this.getUrl("room-update-batches-list")();
    return this.get<RoomUpdateBatch[]>(url);
  }

  public getBatch(batchId: RoomUpdateBatch["id"]) {
    const url = this.getUrl("room-update-batches-detail")(batchId);
    return this.get<RoomUpdateBatch>(url);
  }

  public getBatchLogs(batchId: RoomUpdateBatch["id"]) {
    const url = this.getUrl("room-update-batches-log-items-list")(batchId);
    return this.get<RoomUpdateBatchLogItem[]>(url);
  }

  public applyBatch(
    batchId: RoomUpdateBatch["id"],
    workflows: WorkflowToApply[],
  ) {
    const url = this.getUrl("room-update-batches-apply")(batchId);
    return this.post<RoomUpdateBatch>(url, workflows);
  }

  public createBatch(creteBatchPayload: CreateBatchPayload) {
    const url = this.getUrl("room-update-batches-list")();
    return this.post<RoomUpdateBatch[]>(url, creteBatchPayload);
  }
}

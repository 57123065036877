<template>
  <TablePageLayout
    :title="pageTitle"
    :search="searchPhrase"
    :action="toolbarAction"
    :flex-body="!!group"
    @search="handleSearch"
    @action="handleAction"
  >
    <DrOverlayEmpty
      v-if="noDisplayData.active"
      icon="bars"
      :title="noDisplayData.title"
      :subtitle="noDisplayData.subtitle"
    >
      <template #action>
        <ElButton
          v-if="noDisplayData.btnClearSearch"
          @click="searchPhrase = ''"
        >
          {{ $t("filters.clear_search_query") }}
        </ElButton>
      </template>
    </DrOverlayEmpty>

    <!-- All groups view -->
    <template v-if="!group">
      <GroupsRequestsTable
        :items="filteredCategories"
        :actions="actions"
        :is-processing="isProcessing"
        @update-actions="$emit('update-actions', $event)"
      />
    </template>
    <!-- Selected group view -->
    <template v-else-if="groupActions">
      <GroupsRequestsTable
        :items="filteredCategories"
        :actions="actions"
        :is-processing="isProcessing"
        :group="group"
        @update-actions="$emit('update-actions', $event)"
        @add-category="$emit('add-category', $event)"
      />

      <SettingsSectionLayout
        :title="$t('permissions.settings.request_management.title')"
      >
        <SettingsSectionItem v-bind="settingsManageRequests" />
        <SettingsSectionItem v-bind="settingsCreateRequests" />
        <SettingsSectionItem v-bind="settingsDeleteRequests" />
        <SettingsSectionItem v-bind="settingsStartDueDates" />
        <SettingsSectionItem v-bind="settingsCustomFields" />
      </SettingsSectionLayout>

      <SettingsSectionLayout
        :title="$t('permissions.settings.new_worklist.title')"
        :subtitle="$t('permissions.settings.new_worklist.subtitle')"
      >
        <SettingsSectionItem v-bind="settingsWorklistAccess" />
      </SettingsSectionLayout>

      <SettingsSectionLayout
        :title="$t('permissions.settings.public_comments.title')"
      >
        <SettingsSectionItem v-bind="settingsPublicComments" />
      </SettingsSectionLayout>
    </template>
  </TablePageLayout>
</template>

<script setup lang="ts">
import fuzzaldrinPlus from "fuzzaldrin-plus";
import { cloneDeep, set } from "lodash-es";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrOverlayEmpty } from "@shared/ui/dr-overlay";

import { TaskFieldAccessType } from "@setups/enums";
import { PermissionMode } from "@drVue/store/modules/room/groups/GroupsApiService";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import SettingsSectionItem from "../SettingsSectionItem.vue";
import SettingsSectionLayout from "../SettingsSectionLayout.vue";
import TablePageLayout from "../TablePageLayout.vue";
import GroupsRequestsTable from "./table/GroupsRequestsTable.vue";

import type { PageToolbarAction } from "../TablePageLayout.vue";
import type {
  Category,
  GroupsActions,
  SettingsSectionItemCheckbox,
  SettingsSectionItemRadio,
} from "../types";
import type { Group } from "./types";
import type { CategoryNavTreeItem } from "@drVue/store/pinia/room/categories/categories";

interface Props {
  group: Group | null;
  actions: GroupsActions;
  isProcessing: boolean;
}

interface Emits {
  (event: "update-actions", actions: GroupsActions): void;
  (
    event: "add-category",
    reference: HTMLElement,
    parent_id?: Category["parent_id"],
  ): void;
}

const { actions, group } = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useI18n();
const categoriesStore = useCategoriesStore(pinia);

const searchPhrase = ref("");

const toolbarAction = computed(() => {
  const action: PageToolbarAction = {
    label: t("permissions.requests.add_worklist"),
    type: "default",
    icon: "plus",
    menu: [],
  };

  return action;
});

const pageTitle = computed(() =>
  group
    ? t("permissions.requests.page_title_selected_group")
    : t("permissions.requests.page_title_all_groups"),
);

const noCategories = computed(() => !categoriesStore.categoriesList.length);

const noDisplayData = computed(() => {
  const data = {
    active: false,
    title: "",
    subtitle: "",
    btnClearSearch: false,
  };

  if (noCategories.value) {
    data.active = true;
    data.title = t("permissions.requests.no_worklists");
    data.subtitle = t("permissions.requests.no_worklists_desc");
  } else if (!filteredCategories.value.length) {
    data.active = true;
    data.title = t("shared.no_results_for", { query: searchPhrase.value });
    data.btnClearSearch = true;
  }

  return data;
});

const handleSearch = (phrase: string) => {
  searchPhrase.value = phrase.trim();
};

const filterCategories = (categories: CategoryNavTreeItem[], query: string) => {
  return categories.reduce<CategoryNavTreeItem[]>((acc, cat) => {
    const children = cat.children ? filterCategories(cat.children, query) : [];

    const score = fuzzaldrinPlus.score(cat.name, query);
    if (score > 0 || children.length) {
      acc.push({
        ...cat,
        children,
      });
    }
    return acc;
  }, []);
};

const filteredCategories = computed(() => {
  const query = searchPhrase.value.toLowerCase();
  if (query) {
    return filterCategories(categoriesStore.categoriesNavTree, query);
  }

  return categoriesStore.categoriesNavTree;
});

const handleAction = (reference: HTMLElement) => {
  emit("add-category", reference);
};

const groupActions = computed(() => {
  if (group) {
    return actions[group.id];
  }
  return null;
});

const updateGroupActions = (path: string, value: unknown) => {
  if (!groupActions.value) return;

  const payload = cloneDeep(actions);
  set(payload, `${group!.id}.${path}`, value);

  emit("update-actions", payload);
};

const settingsManageRequests = computed(() => {
  return {
    item: {
      type: "checkbox",
      isChecked: groupActions.value!.canManageTasks,
      name: t("permissions.settings.request_management.manage_details"),
    } satisfies SettingsSectionItemCheckbox,
    onUpdateCheckbox: (isChecked: boolean) =>
      updateGroupActions("canManageTasks", isChecked),
  };
});

const settingsCreateRequests = computed(() => {
  return {
    item: {
      type: "checkbox",
      isDisabled: !groupActions.value!.canManageTasks,
      isChecked: groupActions.value!.canCreateTasks,
      name: t("permissions.settings.request_management.create_requests"),
    } satisfies SettingsSectionItemCheckbox,
    onUpdateCheckbox: (isChecked: boolean) =>
      updateGroupActions("canCreateTasks", isChecked),
  };
});

const settingsDeleteRequests = computed(() => {
  return {
    item: {
      type: "checkbox",
      isDisabled: !groupActions.value!.canManageTasks,
      isChecked: groupActions.value!.canDeleteTasks,
      name: t("permissions.settings.request_management.delete_requests"),
    } satisfies SettingsSectionItemCheckbox,
    onUpdateCheckbox: (isChecked: boolean) =>
      updateGroupActions("canDeleteTasks", isChecked),
  };
});

const hasAccessVariants: Required<SettingsSectionItemCheckbox>["variants"]["items"] =
  [
    {
      id: TaskFieldAccessType.View,
      name: t("shared.access.view"),
    },
    {
      id: TaskFieldAccessType.Edit,
      name: t("shared.access.edit"),
    },
  ];

const settingsStartDueDates = computed(() => {
  const access = groupActions.value!.task_start_and_due_dates_access;
  const isChecked = access !== TaskFieldAccessType.NoAccess;

  return {
    item: {
      type: "checkbox",
      isDisabled: !groupActions.value!.canManageTasks,
      isChecked,
      name: t("permissions.settings.request_management.start_due_dates"),
      variants: {
        items: hasAccessVariants,
        value: access,
      },
    } satisfies SettingsSectionItemCheckbox,
    onUpdateCheckbox: (isChecked: boolean) => {
      updateGroupActions(
        "task_start_and_due_dates_access",
        isChecked ? TaskFieldAccessType.View : TaskFieldAccessType.NoAccess,
      );
    },
    onUpdateCheckboxVariant: (variantId: string) => {
      updateGroupActions("task_start_and_due_dates_access", variantId);
    },
  };
});

const settingsCustomFields = computed(() => {
  const access = groupActions.value!.taskCustomFieldsAccess;
  const isChecked = access !== TaskFieldAccessType.NoAccess;

  return {
    item: {
      type: "checkbox",
      isDisabled: !groupActions.value!.canManageTasks,
      isChecked,
      name: t("permissions.settings.request_management.custom_fields"),
      variants: {
        items: hasAccessVariants,
        value: access,
      },
    } satisfies SettingsSectionItemCheckbox,
    onUpdateCheckbox: (isChecked: boolean) => {
      updateGroupActions(
        "taskCustomFieldsAccess",
        isChecked ? TaskFieldAccessType.View : TaskFieldAccessType.NoAccess,
      );
    },
    onUpdateCheckboxVariant: (variantId: string) => {
      updateGroupActions("taskCustomFieldsAccess", variantId);
    },
  };
});

const settingsPublicComments = computed(() => {
  return {
    item: {
      type: "checkbox",
      isChecked: groupActions.value!.need_comments_approve,
      name: t("permissions.settings.public_comments.must_approve"),
    } satisfies SettingsSectionItemCheckbox,
    onUpdateCheckbox: (isChecked: boolean) =>
      updateGroupActions("need_comments_approve", isChecked),
  };
});

const settingsWorklistAccess = computed(() => {
  const value = groupActions.value!
    .category_default_permissions_mode as PermissionMode;

  let radioValue = value;
  if (value === PermissionMode.InheritNoConflict) {
    radioValue = PermissionMode.InheritAlways;
  }

  return {
    item: {
      type: "radio",
      value: radioValue,
      variants: [
        {
          id: PermissionMode.InheritAlways,
          name: t("permissions.settings.new_worklist.inherit_access"),
          params: [
            {
              id: PermissionMode.InheritNoConflict,
              name: t(
                "permissions.settings.new_worklist.inherit_access_on_diff_perms",
              ),
              isChecked: value === PermissionMode.InheritNoConflict,
            },
          ],
        },
        {
          id: PermissionMode.DenyAll,
          name: t("permissions.settings.new_worklist.deny_access"),
        },
      ],
    } satisfies SettingsSectionItemRadio,
    onUpdateRadio: (
      newValue: PermissionMode.InheritAlways | PermissionMode.DenyAll,
    ) => {
      updateGroupActions("category_default_permissions_mode", newValue);
    },
    onUpdateRadioParam: (newValue: PermissionMode.InheritNoConflict) => {
      if (value === newValue) {
        updateGroupActions(
          "category_default_permissions_mode",
          PermissionMode.InheritAlways,
        );
      } else {
        updateGroupActions(
          "category_default_permissions_mode",
          PermissionMode.InheritNoConflict,
        );
      }
    },
  };
});
</script>

<template>
  <!-- fixme: room -> deal styles -->
  <div class="dash-room-notes__edit_container">
    <ElForm
      ref="noteForm"
      :model="noteModel"
      :disabled="isSubmitting"
      :rules="rules"
      @submit.prevent="formSubmit"
    >
      <DrEditor
        v-model="noteModel.body"
        :options="editorOptions"
        @update:model-value="onModelUpdate"
        @focus="isExpanded = true"
      />
      <ElFormItem
        v-if="isExpanded || note.id"
        prop="body"
        class="notes-list__edit-footer"
      >
        <ElButton
          v-if="noteModel.id"
          class="text-uppercase"
          type="default"
          @click="close"
        >
          Cancel
        </ElButton>
        <ElButton
          type="primary"
          :disabled="!isChanged"
          :loading="isSubmitting"
          @click="formSubmit"
        >
          {{ noteModel.id ? "Save" : "Add note" }}
        </ElButton>
      </ElFormItem>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";
import {
  loadCommentDraft,
  removeCommentDraft,
  storeCommentDraft,
} from "@shared/lib/storage.ts";
import DrForm from "@shared/ui/dr-form";
import { DrEditor } from "@shared/ui/editor";
import { watchDebounced } from "@vueuse/core";

import { DashboardCardEvent, insightTrack } from "@app/insight";

import type { Note } from "@drVue/store/modules/client-dashboard/notes/types";
import type { ElForm } from "element-plus";
import type { PropType } from "vue";

interface Data {
  noteModel: Note;
  rules: any;
  isExpanded: boolean;
}

export default defineComponent({
  name: "NoteEditForm",
  components: {
    DrEditor,
  },
  extends: DrForm,
  props: {
    notesStorePrefix: { required: true, type: String as PropType<string> },
    objectId: {
      required: true,
      type: [String, Number] as PropType<number | string>,
    },
    note: { required: true, type: Object as PropType<Note> },
    placeholder: {
      required: false,
      type: String as PropType<string>,
      default: "Start typing to leave a note...",
    },
  },
  emits: ["close"],
  data(): Data {
    return {
      noteModel: { body: null } as Note,
      rules: {
        body: [
          { required: true, message: "Please input note", trigger: "blur" },
        ],
      },
      isExpanded: false,
    };
  },
  computed: {
    storeKey() {
      return `dr:edit_form:${this.objectId}_${this.note.id || "new"}`;
    },
    editorOptions(): any {
      const showMenuOnFocus = !this.note.id;
      const { placeholder } = this;
      return {
        showMenuOnFocus,
        placeholder,
      };
    },
  },
  created() {
    watchDebounced(
      () => this.noteModel.body,
      (body) => {
        storeCommentDraft(this.storeKey, body as any);
      },
      { debounce: 200, maxWait: 500, deep: true },
    );
  },
  beforeMount() {
    this.updateNoteModel();
  },
  methods: {
    onModelUpdate() {
      this.isChanged = true;
    },
    close() {
      this.$emit("close");
    },
    updateNoteModel() {
      this.noteModel = cloneDeep(this.note);
      // If the note is open for editing (has an id), but there is a saved draft in localeStorage,
      // it means that the user did not save the note during editing and it is worth substituting the data from localeStorage.
      const isEditing = Boolean(this.noteModel.id);
      const storedDraft = loadCommentDraft(this.storeKey);
      if (!this.noteModel.body || (isEditing && storedDraft)) {
        this.noteModel.body = loadCommentDraft(this.storeKey);
        this.isChanged = true;
      }
    },
    formSubmit(): any {
      (this.$refs["noteForm"] as typeof ElForm).validate((valid: boolean) => {
        if (valid) {
          let actionPromise;
          const action = this.noteModel.id ? "update" : "create";
          if (action == "create")
            actionPromise = this.submitAction(
              `${this.notesStorePrefix}/createNote`,
              { objectId: this.objectId, note: this.noteModel },
            );
          else
            actionPromise = this.submitAction(
              `${this.notesStorePrefix}/updateNote`,
              this.noteModel,
            );
          return actionPromise.then(() => {
            insightTrack(
              this.noteModel.id
                ? DashboardCardEvent.Modified
                : DashboardCardEvent.Added,
            );

            removeCommentDraft(this.storeKey);
            this.close();
            if (action == "create") {
              this.updateNoteModel();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
});
</script>

import { NG_APP_ID } from "@shared/ui/angular/constants";

import type { EmtpyObject } from "@app/vue/types";
import type { FieldItem } from "@drVue/store/modules/client-dashboard/fields/types";
import type {
  Batch,
  BatchItem,
  DrUploadDirectory,
  DrUploadFile,
  UploadConfig,
  UploadReponse,
} from "@shared/ui/dr-upload-dialog/types";
import type { AxiosResponse } from "axios";

function getAngularService(serviceName: string) {
  const el = document.getElementById(NG_APP_ID);
  if (!el || el.className.indexOf("ng-scope") === -1) {
    throw "Angular is not loaded";
  }
  return (angular.element(el) as any).injector().get(serviceName);
}

abstract class BaseServiceProxy {
  abstract readonly SERVICE_NAME: string;
  private service: any = null;

  protected getService() {
    if (this.service === null) {
      this.service = getAngularService(this.SERVICE_NAME);
    }
    return this.service;
  }
}

export class TasksServiceProxy extends BaseServiceProxy {
  SERVICE_NAME = "TasksService";

  public openTaskDetails(taskKey: string) {
    this.getService().openTaskDetails(taskKey);
  }
}

export class TasksFilterServiceProxy extends BaseServiceProxy {
  SERVICE_NAME = "TasksFilterService";

  public isActive() {
    return this.getService().isActive();
  }

  public hasColumnSorting() {
    const service = this.getService();
    return service.order.by != "order" || service.order.reverse;
  }

  public clearListFilters() {
    return this.getService().clearListFilters();
  }

  public get filters() {
    return this.getService().filters;
  }

  public setCustomFields(fields: FieldItem[]) {
    return this.getService().setCustomFields(fields);
  }
}

export class DocumentServiceProxy extends BaseServiceProxy {
  SERVICE_NAME = "DocumentsService";

  public bookmarkItems(
    folders?: number[],
    documents?: number[],
    setBookmarked: boolean = false,
  ): Promise<void> {
    return this.getService().bookmarkItems(folders, documents, setBookmarked);
  }
}

export interface UploadToFolderFile {
  id: number;
  uid: string;
  index: string;
  name: string;
  upload_information:
    | EmtpyObject
    | { new_name: string }
    | { new_version: string };
}

export interface UploadToFolderResponse {
  info: any;
  files: AxiosResponse<UploadToFolderFile>[];
}

type UploadPromise = {
  then(
    onFulfilled: (response: UploadReponse) => void,
    onRejected: (error: AxiosResponse) => void,
    progressBack: (event: ProgressEvent) => void,
  ): UploadPromise;
  finally(cb: () => void): UploadPromise;
};

export class FileUploadService extends BaseServiceProxy {
  SERVICE_NAME = "FileUploadService";

  public uploadToFolder(
    folderId: number,
    items: BatchItem[],
    permissions:
      | Record<number, [boolean, boolean, boolean, boolean]>
      | undefined,
    notify: boolean | number[],
  ): Promise<UploadToFolderResponse> {
    return this.getService().uploadToFolder(
      folderId,
      items,
      permissions,
      notify,
    );
  }

  public getUploadPromise(uploadConfig: UploadConfig): UploadPromise {
    return this.getService().getUploadPromise(uploadConfig);
  }
}

export class FileUploadHelpers extends BaseServiceProxy {
  SERVICE_NAME = "FileUploadHelpers";

  public splitToBatches(
    files: (File | DrUploadFile | DrUploadDirectory)[],
  ): Batch[] {
    return this.getService().splitToBatches(files);
  }

  public renameBatch(batch: Batch, newName: string) {
    this.getService().renameBatch(batch, newName);
  }
}
